/* Grid */
.gridPrimaryLine {
  stroke: lightgray;
  stroke-width: 1;
}
.gridSecondaryLine {
  stroke: lightgray;
  stroke-width: 0.5;
}
.gridCross {
  stroke: gray;
  stroke-width: 1;
}
/* Draw */
.fBody {
  stroke: black;
  fill: rgba(0, 0, 100, 0.1);
  stroke-width: 2;
  stroke-dasharray: 13;
  stroke-dashoffset: 10;
}
.secondaryLine {
  stroke: black;
  stroke-width: 1;
  fill: none;
}
.dlLine {
  stroke-dasharray: 15 10;
}

.wlLine {
  stroke: blue;
  stroke-dasharray: 25 10 5 10;
}

/* Hatch */
.soils {
  /*stroke: red;*/
  /*fill: none;*/
}
.trashHatch,
.sandHatch,
.clayHatch {
  stroke: brown;
  stroke-width: 1;
}
.aflLine {
  stroke: Sienna;
  stroke-width: 3;
  stroke-dasharray: 13;
  stroke-dashoffset: 10;
}
.zLine {
  stroke: Sienna;
  stroke-width: 2;
  stroke-dasharray: 13;
  stroke-dashoffset: 10;
}
.waterBg {
  stroke: transparent;
  fill: rgba(10, 18, 247, 0.2);
  pointer-events: none;
}
/* Dimensions and Marks */
.dimLine {
  stroke: blue;
  stroke-width: 1;
  fill: none;
}

.dimArrow {
  stroke: blue;
  stroke-width: 3;
  fill: none;
}
.levelMarkText {
  fill: blue;
  font-family: Arial;
  font-style: italic;
  font-size: 17px;
}
.dimText {
  fill: blue;
  font-family: Arial;
  font-style: italic;
  font-size: 17px;
}
.wlText {
  fill: maroon;
  font-family: Arial;
  font-size: 20px;
}
.wlMarker {
  fill: none;
  stroke: maroon;
  stroke-width: 3;
}
