@media print {
    html,
    body {
        background-color: white !important;
        height: auto !important;
        overflow: hidden !important;
    }

    #printTitle,
    .footer,
    .taskMenu,
    .temp-navbar,
    header {
        display: none !important;
    }

    #root {
        overflow: hidden !important;
        height: auto !important;
    }

    #mainPaper {
        box-shadow: none;
        overflow-y: hidden !important;
        contain: none !important;
        height: auto !important;
    }

    #mainPaper > div:first-of-type {
        overflow: hidden !important;
        height: auto !important;
    }

    .mainContainer {
        width: 210mm !important;
        margin: 0 !important;
        overflow-y: hidden !important;
        overflow-x: hidden !important;
        height: auto !important;
    }
    .mainContainer > div {
      height: auto !important;
    }
    .paperContainer {
        width: 100%;
        max-width: 100% !important;
        flex-basis: unset !important;
        height: auto !important;
    }
    .sheetHelper {
      overflow-y: hidden !important;
      overflow-x: hidden !important;
      max-height: auto !important;
      height: auto !important;
    }
    main {
      height: auto !important;
    }

    .sheetA4 {
        margin: 0 !important;
        padding: 0 !important;
        font-family: 'EB Garamond' !important;
        border: none !important;
        height: auto !important;
    }
    .sheetA4 article {
        padding-left: 40px !important;
    }

    .settlementTable tr th:nth-child(2),
    .settlementTable tr td:nth-child(2),
    .settlementTable tr th:nth-child(3),
    .settlementTable tr td:nth-child(3) {
        display: none;
    }

    .page-break {
        display: block;
        page-break-before: always;
    }
}

/* @media screen and (max-width: 1200px) {
    .mainContainer {
        min-width: 100vw !important;
    }
} */

html,
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: rgb(245, 245, 245);
}
#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

#mainPaper > div:first-of-type,
#news > div:first-of-type {
    margin: 10px auto;
    height: calc(100% - 20px);
    overflow: auto !important;
}

.temp-navbar {
    min-height: 80px;
}
.mainContainer {
    display: flex;
    width: 90%;
    height: calc(100% - 60px);
    flex-grow: 1;
}
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    min-height: 20px;
    padding: 5px;
    font-size: 14px;
    color: gray;
}

.mail {
    color: black;
}

a {
    text-decoration: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}

.task-list-container {
    height: 100%;
    contain: strict;
    overflow-y: auto;
}
