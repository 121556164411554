.sheetHelper {
  max-height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
}
.sheetA4 {
  margin: auto;
  width: 695px;
  padding: 5px 5px 5px 25px;
  font-family: Calibri;
  text-align: center;
  background: white;
  border: 1px dashed black;
  overflow-y: auto;
  overflow-x: hidden;
}
.sheetA4 ul {
  list-style: none;
}
.soilsTable {
  font-size: 15px;
  margin: auto;
  border-collapse: collapse;
}
.soilsTable th {
  background-color: lightyellow;
}
.soilsTable td,
.soilsTable th {
  padding: 5px;
  border: 1px solid gray;
}
.settlementTable {
  font-size: 13px;
}
.settlementConditionRow {
  font-weight: bold;
  background-color: lightgreen;
}
.hiddenWeakLayerRow {
  display: none;
}
.reportAttention {
  margin: 5px 20px 5px 5px;
  padding: 5px 10px;
  border-left: 5px solid orange;
  background-color: rgba(255, 165, 0, 0.1);
}
.reportResult {
  margin: 5px 20px 5px 5px;
  padding: 5px 10px;
  border-left: 5px solid green;
  background-color: rgba(0, 128, 0, 0.1);
}
.reportResultNegative {
  margin: 5px 20px 5px 5px;
  padding: 5px 10px;
  border-left: 5px solid red;
  background-color: rgba(255, 0, 0, 0.1);
}
mark {
  background-color: orange;
}
/* graph P */
.axiosLine {
    stroke: blue;
    stroke-width: 1;
	  stroke-dasharray: 15, 3, 2, 3;
    fill: none;
}
.axiosMarker {
  stroke: blue;
  fill: blue;
}
.footing {
  stroke: lightgray;
  stroke-width: 1;
  fill: lightgray;
}
.textP {
  stroke: black;
  stroke-width: 1;
  fill: black;
  font-family: Arial;
  font-style: 8;
}
.graphP {
  stroke: orange;
  stroke-width: 1;
  fill: rgba(255, 165, 0, 0.05);
}
.graphPMain {
  stroke: purple;
  stroke-width: 2;
  fill: rgba(128, 0, 128, 0.1);
}
/* Settlement */
.fBodyResult {
  stroke: black;
  fill: rgba(0, 0, 100, 0.05);
  stroke-width: 2;
}
.dotSigmazp {
  stroke: IndianRed;
  stroke-width: 7;
  stroke-linecap: round;
  fill: none;
}
.lineSigmazp {
  stroke: IndianRed;
  stroke-width: 3;
  fill: none;
}
.dotSigmazg {
  stroke: SeaGreen;
  stroke-width: 7;
  stroke-linecap: round;
  fill: none;
}
.lineSigmazg {
  stroke: SeaGreen;
  stroke-width: 3;
  fill: none;
}
.dotSigmazg02 {
  stroke: OliveDrab;
  stroke-width: 7;
  stroke-linecap: round;
  fill: none;
}
.lineSigmazg02 {
  stroke: OliveDrab;
  stroke-width: 3;
  fill: none;
}
.dotSigmazgamma {
  stroke: SteelBlue;
  stroke-width: 7;
  stroke-linecap: round;
  fill: none;
}
.lineSigmazgamma {
  stroke: SteelBlue;
  stroke-width: 3;
  fill: none;
}
.soilLineDef {
  stroke: Sienna;
  stroke-width: 1;
  stroke-dasharray: 10;
  stroke-dashoffset: 7;
}
.trashHatch,
.sandHatch,
.clayHatch {
  stroke: gray;
  stroke-width: 0.5;
}
.legendFirstText {
  font-family: Arial;
  font-style: italic;
  font-size: 20px;
}
.legendSecondText {
  font-family: Arial;
  font-style: italic;
  font-size: 15px;
}
.textSigmazp {
  fill: IndianRed;
}
.textSigmazg {
  fill: SeaGreen;
}
.textSigmazg02 {
  fill: OliveDrab;
}
.textSigmazgamma {
  fill: SteelBlue;
}
